<template>
  <div id="modal-upload-file">
    <modal-template v-bind="config" @modal:on-close="$emit('action:close')">
      <template #modal-body>
        <b-form-group>
          <label> File </label>
          <b-form-file
            v-model="fileTemp"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            :state="isInvalid('fileId').state"
            :disabled="loadingUpload"
            @input="(file) => $emit('action:upload-file', file)"
          ></b-form-file>

          <small v-if="loadingUpload"> 
            Uploading your file
            <b-spinner small label="Small Spinner" class="ml-2"></b-spinner>
          </small>
          <small v-if="!loadingUpload && config.form.fileId">
            {{ config.form.fileId ? 'Your file is ready to save' : '' }}
            <b-icon icon="check-circle" variant="success" class="ml-1"/>
          </small>
          <br/>
          <small v-if="config.state === 'edit'"> Upload a new one to update </small>
          <b-form-invalid-feedback v-if="errors.length">
            {{ isInvalid('fileId').message }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <label> Name </label>
          <b-form-input
            placeholder="Name"
            v-model="config.form.name"
            size="sm"
            :state="isInvalid('name').state"
          />
          <b-form-invalid-feedback v-if="errors.length">
            {{ isInvalid('name').message }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label> Description </label>
          <b-form-textarea
            placeholder="Description"
            v-model="config.form.desc"
            size="sm"
            :state="isInvalid('desc').state"
          />
          <b-form-invalid-feedback v-if="errors.length">
            {{ isInvalid('desc').message }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <label> Type </label>
          <b-form-select 
            v-model="config.form.type" 
            size="sm"
            :state="isInvalid('type').state"
          >
            <option
              v-for="(option, idx) in config.options.type"
              :key="idx"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </b-form-select>
          <b-form-invalid-feedback v-if="errors.length">
            {{ isInvalid('type').message }}
          </b-form-invalid-feedback>
        </b-form-group>
      </template>

      <template #modal-footer-custom>
        <b-button
          variant="secondary"
          size="sm"
          @click.prevent="$emit('action:close')"
          :disabled="loadingUpload || loadingSave"
        >
          Close
        </b-button>
        <b-button
          size="sm"
          variant="pompe-primary"
          @click.prevent="$emit('action:submit-upload')"
          :disabled="loadingUpload || loadingSave"
        >
          <span v-if="!loadingSave">
            {{ config.state === "edit" ? "Update" : "Upload" }}
          </span>
          <span v-else> <b-spinner small /> </span>
        </b-button>
      </template>
    </modal-template>
  </div>
</template>

<script>
import ModalTemplate from "@/components/templates/ModalTemplate.vue";

export default {
  components: {
    ModalTemplate,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => ([])
    },
    loadingUpload: {
      type: Boolean,
      default: false,
    },
    loadingSave: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fileTemp: null,
  }),
  methods: {
    isInvalid(field) {
      let error = this.errors.find(el => el.field === field);
      return {
        state: error && !this.config.form[field] ? false : null,
        message: error ? error.message : ''
      }
    }
  }
};
</script>

<style></style>
