<template>
  <div id="modal-template">
    <b-modal 
      id="modal-template" 
      v-model="showModal"
      :title="title"
      title-tag="h6"
      :size="modalSize"
      :centered="isCentered"
      :hide-footer="hideFooter"
      :hide-header-close="hideHeaderClose"
      :no-close-on-backdrop="true"
      :no-close-on-esc="escKeyClose"
      @close="$emit('modal:on-close')"
      @cancel="$emit('modal:on-cancel')"
    >
      <div id="modal-body">
        <slot name="modal-body"></slot>
      </div>

      <template #modal-footer>
        <slot name="modal-footer-custom"></slot>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Modal Title'
    },
    show: {
      type: Boolean,
      default: false,
    },
    isCentered: {
      type: Boolean,
      default: true
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    // sm, md, lg, xl
    modalSize: {
      type: String,
      default: 'md'
    },
    backDropClose: {
      type: Boolean,
      default: true
    },
    hideHeaderClose: {
      type: Boolean,
      default: false,
    },
    escKeyClose: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showModal: false,
  }),
  watch: {
    show: {
      handler(val) {
        this.showModal = val;
      }
    }
  }
}
</script>

<style>

</style>